import Advertisement from './Advertisement';
import Copyrights from './Copyrights';
import DownloadApp from './DownloadApp';
import PopularArticles from './PopularArticles';

export default {
  Advertisement,
  Copyrights,
  PopularArticles,
  DownloadApp,
}