export default {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  black50: '#080d1f',
  black100: '#1f1f1f',
  black22: '#222222',
  black33: '#333333',
  black40: '#404040',
  black66: '#666666',
  black77: '#777777',
  black84: '#848484',
  black99: '#999999',
  blackAA: '#aaaaaa',
  blackBF: '#bfbfbf',
  blackCC: '#cccccc',
  blackD9: '#d9d9d9',
  blackEB: '#ebebeb',
  blackF5F6F7: '#f5f6f7',
  blackF7: '#f7f7f7',
  primary: '#147BF9',
  primary2: '#2e5ce6',
  primary3: '#3c6af2',
  primary4: '#4467f1',
  primaryE: '#ebf2fa',
  primaryF: '#f5f6f7',
  greyA08: 'rgba(255, 255, 255, 0.8)',
  notDimmed: 'rgba(47, 52, 55, 0)',
  dimmed: 'rgba(15, 15, 15, 0.75)',
  bottomSheetContentWrapper: 'rgb(247, 246, 243)',
};
